/* eslint-disable */
import $ from 'jquery';
import ymaps from 'ymaps';
import '@fancyapps/fancybox';
import 'jquery-mask-plugin';
import AOS from 'aos';
import Swiper from 'swiper';
import { CountUp } from 'countup.js';

window.$ = $;
window.jQuery = $;

// works slider
$(() => {
    new Swiper('.works .swiper-container', {
        spaceBetween: 30,
        slidesPerView: 1,
        loop: true,
        grabCursor: true,
        navigation: {
          nextEl: '.works .swiper-button-next',
          prevEl: '.works .swiper-button-prev',
        },
    });
});

// start slider
$(() => {
  new Swiper('.start-slider.swiper-container', {
      spaceBetween: 0,
      slidesPerView: 1,
      loop: true,
      grabCursor: true,
      autoplay: {
        delay: 4000,
      }
  });
});

// gallery slider
$(() => {
  const galleryThumbs = new Swiper('.gallery .gallery-thumbs', {
    lazy: true,
    spaceBetween: 30,
    slidesPerView: 5,
    watchSlidesVisibility: true,
    watchSlidesProgress: true
  });
  new Swiper('.gallery .gallery-top', {
    lazy: true,
    spaceBetween: 30,
    grabCursor: true,
    thumbs: {
      swiper: galleryThumbs
    }
  });
});

// gallery-work slider
$(() => {
  const galleryThumbsWork = new Swiper('.gallery-work .gallery-work-thumbs', {
    lazy: true,
    spaceBetween: 30,
    slidesPerView: 5,
    watchSlidesVisibility: true,
    watchSlidesProgress: true
  });
  new Swiper('.gallery-work .gallery-work-top', {
    lazy: true,
    spaceBetween: 30,
    grabCursor: true,
    thumbs: {
      swiper: galleryThumbsWork
    }
  });
});

// clients slider
$(() => {
  new Swiper('.us-clients .swiper-container', {
    loop: true,
    navigation: {
      nextEl: '.us-clients .us-clients_next'
    },
  });
});

// map
$(() => {
  const map = $('#map');
  if (map.length) {
      ymaps.load().then((maps) => {
          const coords = [59.212823, 39.846984];

          const myMap = new maps.Map('map', {
              center: coords,
              zoom: 17,
              controls: []
          }, {
              searchControlProvider: 'yandex#search',
          });
          const myPlacemark = new maps.Placemark(coords, {
              balloonContent: 'г. Вологда, ул. Кирпичная, д. 26, оф. 2'
          }, {
              preset: 'islands#dotIcon',
              iconColor: '#226E38'
          });

          myMap.geoObjects.add(myPlacemark);
          myMap.behaviors.disable('scrollZoom');
          map.addClass('noZoom');
          myMap.events.add('click', () => {
              if (map.hasClass('noZoom')) {
                  map.removeClass('noZoom').addClass('yesZoom');
                  myMap.behaviors.enable('scrollZoom');
              } else {
                  map.removeClass('yesZoom').addClass('noZoom');
                  myMap.behaviors.disable('scrollZoom');
              }
          });

          const isMobile = {
              Android: () => (navigator.userAgent.match(/Android/i)),
              BlackBerry: () => (navigator.userAgent.match(/BlackBerry/i)),
              iOS: () => (navigator.userAgent.match(/iPhone|iPad|iPod/i)),
              Opera: () => (navigator.userAgent.match(/Opera Mini/i)),
              Windows: () => (navigator.userAgent.match(/IEMobile/i)),
              any: () => (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
          };

          if (isMobile.any()) {
              myMap.behaviors.disable('drag');
          }
      }).catch(error => console.log('Failed to load Yandex Maps', error));
  }
});

// mob-menu
$(() => {
  const burger = $('.burger');
  const menu = $('.mob-menu');
  const link = $('.mob-menu__menu a');
  const overlay = $('.overlay');

  burger.on('click', function() {
      if($(this).hasClass('active')) {
          $(this).removeClass('active').addClass('not-active');
          overlay.removeClass('fadeIn').addClass('fadeOut');
          menu.removeClass('open');
      } else {
          $(this).removeClass('not-active').addClass('active');
          overlay.removeClass('fadeOut').addClass('fadeIn');
          menu.addClass('open');
      }
  });
  overlay.on('click', () => {
      overlay.removeClass('fadeIn').addClass('fadeOut');
      menu.removeClass('open');
      burger.removeClass('active').addClass('not-active');
  });
  link.on('click', () => {
      overlay.removeClass('fadeIn').addClass('fadeOut');
      menu.removeClass('open');
      burger.removeClass('active').addClass('not-active');
  });
});

AOS.init({
  duration: 600,
  offset: 160
});

// scroll
$(() => {
  $("[scrollto]").click(function(e) {
      e.preventDefault();
      const idBlock = $(this).attr('scrollto');
      $("html, body").animate({
          scrollTop: $(idBlock).offset().top
      }, 1500);
  });
});

// popup
$(() => {
  const overlay = $('.overlay');
  const popupForm = $('.popup');
  const close = $('.popup_close');
  $('.target-callback').on('click', function(e) {
      e.preventDefault();

      overlay.removeClass('fadeOut').addClass('fadeIn');
      popupForm.removeClass('fadeOut').addClass('fadeIn');
  });
  close.on('click', () => {
      overlay.removeClass('fadeIn').addClass('fadeOut');
      popupForm.removeClass('fadeIn').addClass('fadeOut');
  });
  overlay.on('click', () => {
      overlay.removeClass('fadeIn').addClass('fadeOut');
      popupForm.removeClass('fadeIn').addClass('fadeOut');
  });
});

// counters
$(() => {
  function ViewElement() {}
  
  ViewElement.prototype = {
      constructor: ViewElement,
      isElementInView: function (element, fullyInView) {
          var pageTop = $(window).scrollTop();
          var pageBottom = pageTop + $(window).height();
          var elementTop = $(element).offset().top;
          var elementBottom = elementTop + $(element).height();
  
          if (fullyInView === true) {
              return ((pageTop < elementTop) && (pageBottom > elementBottom));
          } else {
              return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
          }
      }
  };
  const viewElement = new ViewElement();

  const options = {
      useEasing: false,
      separator: '',
    };
    let experienceObjects = new CountUp('experience-objects', +$('#experience-objects').text(), options);
    let experiencePlaces = new CountUp('experience-places', +$('#experience-places').text(), options);
    let experienceReviews = new CountUp('experience-reviews', +$('#experience-reviews').text(), options);
    
    $(window).on('scroll', () => {
      const isElementInView = viewElement.isElementInView($('#experience'), false);
      if (isElementInView) {
        experienceObjects.start();
        experiencePlaces.start();
        experienceReviews.start();
      } else {
        experienceObjects.reset();
        experiencePlaces.reset();
        experienceReviews.reset();
      }
    });
});